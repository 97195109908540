import { Avatar, Button, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { marmaLogo, marmaOnlyLogo, RewardCoin } from '../../assets/image_files'
import Dropdown from '../ui/Dropdown'
import HeatMapChart from '../dashboard/HeatMapChart'
import DashboardPieChart from '../../assets/progress-piechart'
import SWAccuracyChart from '../../assets/strength-chart'
import Fchart from '../../assets/frequency-chart'
import DashboardStackedBarChart from '../../assets/progress-barchart'
import { METRICS } from '../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CodeRounded, ScreenshotMonitorOutlined, ShareOutlined, ShowChartRounded } from '@mui/icons-material'
import { Drawer, Tooltip as MaterialTooltip } from "@material-ui/core"
import { faDatabase, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { yellow } from '@mui/material/colors'
import { takeScreenshot } from '../../utils/screenShot'
import ShareOnSocial from 'react-share-on-social'
import { getUniqueId } from '../../utils/common-function'
import QRCode from "react-qr-code";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
const PublicDashboarLayout = () => {
    const [interval, setInterval] = useState("Daily")
    const [metric, setMetric] = useState("questionsSolved")

    const [isSubmissionChartVisible, setIsSubmissionChartVisible] = useState(false)
    const [barChartData, setBarChartData] = useState([])
    const [rewardPoints, setRewardPoints] = useState("")
    const [data, setData] = useState()

    // for side nav
    const [isSideNavOpen, setIsSideNavOpen] = useState(false);
    const toggleSideNav = () => {
        setIsSideNavOpen(!isSideNavOpen);
    };

    // side nav end

    const handleUserData = (data) => setData(data)


    const [open, setOpen] = useState(false)

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen)
    }

    return (
            <div className='bg-[#232627]'>
                <nav
                    className="dashboard-nav"
                    style={{ top: "0px", height: "50px" }}>
                    <img
                        src={marmaLogo}
                        alt="Marma AI"
                        className="h-8 object-fit bg-transparent px-2 ml-1 "
                    />
                    <div className="ms-auto flex gap-4 px-4 items-center sm:hidden hidden md:flex">

                        <a href="/signup" className="hover:text-gray-400 text-white transition-all">Register</a>
                        <a href="/Signin" className="text-white bg-gray-700 hover:bg-gray-600 font-medium rounded-lg text-sm px-5 py-2 transition-all">Log In</a>
                    </div>
                    <div className="flex sm:flex ms-auto md:hidden px-4">
                        <Menu onClick={toggleDrawer(true)} />
                    </div>
                </nav>
                
                {/* side nav goes here */}
                <aside className={`fixed !z-60 top-0 left-0 w-64 h-screen px-2`}>
                    <div className='pt-32 h-full relative'>
                        {data && (
                            <div>
                                <div className='flex justify-center items-center flex-col p-4'>
                                    <Avatar sx={{ bgcolor: yellow[900], width: 68, height: 68 }} className='mb-2 !text-3xl'>
                                        {data[0].name?.charAt(0)}
                                    </Avatar>
                                    <Typography className='text-white'>{data && data[0]?.name}</Typography>
                                </div>


                                {data[0]?.totalRewardPoints && (
                                    <div className='flex justify-center items-center gap-2 p-2 bg-amber-300 bg-opacity-5 rounded-lg mx-auto' style={{ maxWidth: "90%" }}>
                                        <img src={RewardCoin} alt="reward" width={25} />
                                        <h4 className="text-[#FEA117] font-bold text-center text-lg">
                                            {data[0]?.totalRewardPoints}
                                        </h4>
                                    </div>
                                )}

                                <ul className="text-white mt-4">
                                    {data &&
                                        data?.map((entry, index) => (
                                            <li
                                                key={index}
                                                className="">
                                                <div className="flex-row-container gap-[0.25rem]">
                                                    {entry.totalQuestionsSolved > 0 && (
                                                        <MaterialTooltip
                                                            placement="bottom"
                                                            title="Total Questions Solved"
                                                            arrow="true">
                                                            <div className="header gap-1">
                                                                <ShowChartRounded className='!text-[#65D2FF]' />
                                                                <span className='!text-gray-200'>{entry.totalQuestionsSolved}</span>
                                                            </div>
                                                        </MaterialTooltip>
                                                    )}
                                                    {entry.totalPythonQuestionsSolved > 0 && (
                                                        <MaterialTooltip
                                                            placement="bottom"
                                                            title="Total Python Questions Solved"
                                                            arrow="true">
                                                            <div className="header gap-1">
                                                                <CodeRounded className='!text-[#FFA117]' />
                                                                <span className='!text-gray-200'>{entry.totalPythonQuestionsSolved}</span>
                                                            </div>
                                                        </MaterialTooltip>
                                                    )}
                                                    {entry.totalSQLQuestionsSolved > 0 && (
                                                        <MaterialTooltip
                                                            placement="bottom"
                                                            title="Total SQL Questions Solved"
                                                            arrow="true">
                                                            <div className="header gap-1 flex-row-container">
                                                                <FontAwesomeIcon icon={faDatabase} className='!text-[#00B8A3]' />
                                                                <span className='!text-gray-200'>{entry.totalSQLQuestionsSolved}</span>
                                                            </div>
                                                        </MaterialTooltip>
                                                    )}
                                                    {entry.totalExcelQuestionsSolved > 0 && (
                                                        <MaterialTooltip
                                                            placement="bottom"
                                                            title="Total Excel Questions Solved"
                                                            arrow="true">
                                                            <div className="header gap-1">
                                                                <FontAwesomeIcon icon={faFileExcel} className='!text-[#2BBB5D]' />
                                                                <span className='!text-gray-200'>{entry.totalExcelQuestionsSolved}</span>
                                                            </div>
                                                        </MaterialTooltip>
                                                    )}
                                                </div>
                                            </li>
                                        ))}
                                </ul>

                                <div style={{ height: "auto", maxWidth: "90%", width: "100%" }} className='absolute left-1/2 transform -translate-x-1/2 bottom-16 text-center bg-gray-200 bg-opacity-10 p-4 rounded-lg'>
                                    <div className='flex justify-center items-center gap-2 mb-4'>
                                        <img src={marmaOnlyLogo} alt="marma logo" width={30} />
                                        <p className='text-xs font-bold text-white'> Scorecard</p>
                                    </div>

                                    <QRCode
                                        size={80}
                                        style={{ height: "auto", maxWidth: 80, width: "100%", margin: "auto" }}
                                        value={`${window.location.origin}/publicdashboard?id=${getUniqueId(window.location.href)}`}
                                        viewBox={`0 0 80 80`}
                                        bgColor='transparent'
                                        fgColor='white'
                                    />
                                    <p className='text-xs mt-4 text-gray-200 text-opacity-50'>Share your QR code so others can see your progress</p>
                                </div>

                                <div className='absolute left-1/2 transform -translate-x-1/2 bottom-4 text-white flex gap-2'>
                                    <Tooltip
                                        title={`Share your progress`}
                                        arrow>
                                        <button className="bg-gray-200 p-2 rounded-full bg-opacity-10 hover:bg-amber-400 hover:bg-opacity-50 transition-all text-gray-400 hover:text-gray-200">
                                            <ShareOnSocial
                                                textToShare="find a detailed overview of my recent progress and achievements. Whether you're a prospective employer, a fellow professional, or just curious, I invite you to delve into the analytics of my journey."
                                                link={`${window.location.origin}/publicdashboard?id=${getUniqueId(window.location.href)}`}
                                                linkTitle="Welcome to Public Profile"
                                                linkMetaDesc=""
                                                linkFavicon={marmaOnlyLogo}
                                                noReferer>
                                                <ShareOutlined className="!text-md" />
                                            </ShareOnSocial>
                                        </button>
                                    </Tooltip>

                                    <Tooltip
                                        title={`Take a Screenshot`}
                                        arrow>
                                        <button
                                            onClick={() => {
                                                takeScreenshot(document.body);
                                            }}
                                            className="bg-gray-200 p-2 rounded-full bg-opacity-10 hover:bg-amber-400 hover:bg-opacity-50 transition-all text-gray-400 hover:text-gray-200"
                                        >
                                            <ScreenshotMonitorOutlined />
                                        </button>
                                    </Tooltip>
                                </div>

                            </div>
                        )}
                    </div>
                </aside>
                {/* side nav end */}

                <div className="sm:ml-64 min-h-screen">
                    <h2 className="text-xl text-white text-center p-4">Marma Score Card</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 px-4 min-h-[calc(100dvh-105px)]">
                        <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[360px]">
                            <DashboardStackedBarChart
                                interval={interval}
                                metric={metric}
                                forPublic={true}
                                handleBarChartData={(data) => setBarChartData(data)}
                            />
                        </div>


                        <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[360px] overflow-hidden">
                            <div className="mb-3 flex gap-4 w-max mt-1">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${!isSubmissionChartVisible ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
                                    onClick={() => setIsSubmissionChartVisible(false)}>
                                    Overall Progress
                                </Button>

                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${isSubmissionChartVisible ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
                                    onClick={() => setIsSubmissionChartVisible(true)}>
                                    Submissions
                                </Button>

                                <Dropdown
                                    title={METRICS.filter((metricItem) => metricItem.value === metric)[0].key}
                                    icon={metric === 'questionsSolved' ? <CheckCircleIcon className="!text-base" /> : <StarIcon className="!text-base" />}
                                    children={METRICS.map((metric) => (
                                        <MenuItem
                                            key={metric?.key}
                                            value={metric?.value}
                                            onClick={(e) => setMetric(metric.value)}
                                            className="!text-gray-200 !font-light !text-xs">
                                            {metric.key}
                                        </MenuItem>
                                    ))}
                                />
                            </div>
                            {isSubmissionChartVisible ? (
                                <HeatMapChart data={barChartData} />
                            ) : (
                                <DashboardPieChart
                                    metric={metric}
                                    forPublic={true}
                                />
                            )}
                        </div>

                        <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[360px] overflow-hidden">
                            <SWAccuracyChart forPublic={true} />
                        </div>

                        <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[360px] overflow-hidden">
                            <Fchart
                                userRewardPoints={(data) => setRewardPoints(data)}
                                currUserData={handleUserData}
                                forPublic={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default PublicDashboarLayout