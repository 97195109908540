import { TimerRounded } from "@mui/icons-material"
import React, { useState, useEffect, useRef } from "react"
import throttle from "lodash/throttle"
import { updateTimer } from "../utils/apis"

const QuestionTimer = ({ userQuestionHistoryId, elapsedTime, isTimerPaused, onTimeUpdate, onQuestionSwitch, isAnswerSubmitted }) => {
  const [internalElapsedTime, setInternalElapsedTime] = useState(elapsedTime)
  const elapsedTimeRef = useRef(internalElapsedTime)
  const [timeAlreadySent, setTimeAlreadySent] = useState(false) // Track if time was already sent
  const [isVisible, setIsVisible] = useState(true) // Track tab visibility

  useEffect(() => {
    elapsedTimeRef.current = internalElapsedTime
  }, [internalElapsedTime])

  useEffect(() => {
    const handleLogout = () => {
      if (!timeAlreadySent) {
        throttledUpdateBackend(userQuestionHistoryId, elapsedTimeRef.current)
        updateOtherUnsavedTimes()
      }
    }

    window.addEventListener("logoutEvent", handleLogout)

    return () => {
      window.removeEventListener("logoutEvent", handleLogout)
    }
  }, [userQuestionHistoryId, timeAlreadySent])

  // Timer logic, pause when tab is switched or minimized
  useEffect(() => {
    let intervalId

    if (!isTimerPaused && isVisible) {
      intervalId = setInterval(() => {
        setInternalElapsedTime((prevTime) => {
          const newTime = prevTime + 1
          localStorage.setItem(`question-${userQuestionHistoryId}-time`, newTime)
          onTimeUpdate(newTime)
          return newTime
        })
      }, 1000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [isTimerPaused, isVisible, userQuestionHistoryId, onTimeUpdate, internalElapsedTime])

  // Handle tab visibility changes (pause the timer if the tab is not visible)
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden)
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])

  // Function to handle backend update
  const updateBackend = async (userQuestionId, time) => {
    if (timeAlreadySent) {
      console.log("Time already sent, skipping backend update")
      return // If the time was already sent during submission, skip the update
    }

    try {
      const response = await updateTimer({ userQuestionHistoryId: userQuestionId, time })
      if (response.status) {
        console.log(response, time, userQuestionHistoryId)
        // Clear saved time after successful sync
        localStorage.removeItem(`unsaved-time-${userQuestionId}`)
        localStorage.removeItem(`question-${userQuestionId}-time`)
        setTimeAlreadySent(true) // Mark that time has been sent
      }
    } catch (error) {
      console.error("Error updating time:", error)
      // Save unsaved time to localStorage on error for retry
      localStorage.setItem(`unsaved-time-${userQuestionId}`, time)
    }
  }

  // Throttle backend updates to prevent too frequent calls
  const throttledUpdateBackend = throttle(updateBackend, 30000)

  // Retry unsaved time for any question
  const retryUnsavedTime = (userQuestionId) => {
    const unsavedTime = localStorage.getItem(`unsaved-time-${userQuestionId}`)
    if (unsavedTime) {
      throttledUpdateBackend(userQuestionId, unsavedTime) // Retry unsaved time using throttling
    }
  }

  // Update unsaved times for other questions
  const updateOtherUnsavedTimes = async () => {
    for (const key in localStorage) {
      if (key.startsWith("unsaved-time-") && !key.includes(userQuestionHistoryId)) {
        const userQuestionId = key.replace("unsaved-time-", "")
        const unsavedTime = localStorage.getItem(key)
        if (unsavedTime) {
          await updateBackend(userQuestionId, unsavedTime) // Send unsaved time to backend
          localStorage.removeItem(key) // Remove after successful update
        }
      }
    }
  }

  // Handle switching questions (e.g., "Next" button clicked)
  useEffect(() => {
    if (onQuestionSwitch && !timeAlreadySent) {
      throttledUpdateBackend(userQuestionHistoryId, elapsedTimeRef.current)
      updateOtherUnsavedTimes() // Update any unsaved time for other questions
      setInternalElapsedTime(0)
    }
  }, [onQuestionSwitch, timeAlreadySent])

  // Handle page navigation or application close
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!timeAlreadySent) {
        throttledUpdateBackend(userQuestionHistoryId, elapsedTimeRef.current)
        updateOtherUnsavedTimes() // Update unsaved times on unload
      }
      event.returnValue = "" // Standard way to trigger a confirmation dialog
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
      if (!timeAlreadySent) {
        throttledUpdateBackend(userQuestionHistoryId, elapsedTimeRef.current) // Ensure backend is updated on component unmount
        updateOtherUnsavedTimes() // Update unsaved times when component unmounts
      }
    }
  }, [userQuestionHistoryId, timeAlreadySent])

  // Set timeAlreadySent based on isAnswerSubmitted prop
  useEffect(() => {
    if (isAnswerSubmitted) {
      console.log("Answer submitted, pausing timer and marking time as sent")
      setTimeAlreadySent(true) // Mark time as sent since it was included in the answer submission
    }
  }, [isAnswerSubmitted])

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = timeInSeconds % 60
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
  }

  console.log("question Time Rerendering...")
  return (
    <div className="">
      <div className="text-gray-400 p-2 ml-auto ">
        <TimerRounded className="timer-icon me-1 !text-base" />
        <span className="timer-span text-sm text-gray-200 select-none">{formatTime(internalElapsedTime)}</span>
      </div>
    </div>
  )
}

export default React.memo(QuestionTimer)
