import React, { useState, useEffect } from "react"
import "chart.js/auto"
import { Close } from "@mui/icons-material"

import { XAxis, YAxis, Tooltip, Cell, Legend, ResponsiveContainer, ScatterChart, Scatter, ReferenceArea, CartesianGrid } from "recharts"
import Loader from "./loader"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserMetrics } from "../redux/actions/usermetrics"
import { userperformanceData, userarenaperformanceData } from "../utils/apis"
import { getUniqueId } from "../utils/common-function"
import { Button, IconButton } from "@mui/material"
import ToastError from "../components/toasts/ToastError"
import { toastConfig } from "../utils/constants"
import SWChartCustomTooltip from "./SWChartCustomTooltip"


const colorPalette = [
  "#fb7185",
  "#e11d48",
  "#fda4af"
];


const SWAccuracyChart = ({ forPublic, filterParams }) => {
  const [userMetricsData, setUserMetricsData] = useState()
  const userMetrics = useSelector((state) => state.userMetrics.userMetrics)
  const dispatch = useDispatch()
  const [selectedTech, setSelectedTech] = useState("SQL")

  const [disabledIndexes, setDisabledIndexes] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Only store the index of hovered point
  const [highlightedIndex, setHighlightedIndex] = useState(null); // Highlighted point for clicked
  const [tooltipData, setTooltipData] = useState();
  const [isTooltipModalVisible, setIsTooltipModalVisible] = useState(false);
  const techData = {
    SQL: userMetricsData?.SQLFunctions ?? [],
    Excel: userMetricsData?.ExcelFunctions ?? [],
    Python: userMetricsData?.PythonFunctions ?? [],
  }

  const formattedData = techData[selectedTech]?.map((func, index) => ({
    name: func.name,
    totalRuns: func.performance.totalRuns,
    totalCorrectlySubmittedSol: func.performance.totalCorrectlySubmittedSol,
    rewards: func.performance.totalRewards,
    accuracy: (func.performance.totalCorrectlySubmittedSol / func.performance.totalRuns) * 100,
    index
  }))

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(formattedData)
  }, [selectedTech, userMetricsData])


  const handlePointClick = (index) => {
    const overlappingPoints = groupData[`${filteredData[index].rewards},${filteredData[index].accuracy}`];

    if (overlappingPoints.length === 1) {
      // Prevent disabling the only point
      return;
    }

    setDisabledIndexes((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index); // Deselect if already selected
      } else {
        return [...prev, index]; // Otherwise, select the point
      }
    });

    setHighlightedIndex(index); // Highlight clicked point
  };

  // Set the hovered point's index
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null); // Reset hovered index when mouse leaves
  };

  // Clear selected (disabled) points
  const clearFilter = () => {
    setFilteredData(formattedData);
    setDisabledIndexes([]);
    setHighlightedIndex(null); // Clear highlighted state
  };

  useEffect(() => {
    // Filter out the disabled points
    setFilteredData(
      formattedData?.filter((point) => !disabledIndexes.includes(point.index))
    );
  }, [disabledIndexes]);


  // Group points by (rewards, accuracy)
  const groupPoints = (data) => {
    const grouped = {};
    data?.forEach((point) => {
      const key = `${point.rewards},${point.accuracy}`; // Group by rewards and accuracy
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(point);
    });
    return grouped;
  };

  const groupData = groupPoints(filteredData);

  useEffect(() => {
    const getData = async () => {
      if (!forPublic) {
        if (filterParams.filterType === "Free Style") {
          dispatch(fetchUserMetrics())
          setUserMetricsData(userMetrics)
          if (!userMetrics) toast(<ToastError message={"Unable to Fetch Data"} />, toastConfig)
        } else {
          const uniqueId = forPublic ? getUniqueId(window.location.href) : null
          const responseData = await userarenaperformanceData(uniqueId, filterParams)
          if (!responseData) toast(<ToastError message={"Unable to Fetch Data"} />, toastConfig)
          setUserMetricsData(responseData.data)
        }
      } else {
        const uniqueId = getUniqueId(window.location.href)
        const responseData = await userperformanceData(uniqueId, filterParams)
        if (!responseData) toast(<ToastError message={"Unable to Fetch Data"} />, toastConfig)
        setUserMetricsData(responseData.data)
      }
    }
    getData()
  }, [filterParams])




  const maxX = Math.ceil(Math.max(...formattedData.map((item) => item.accuracy)))
  const maxY = Math.ceil(Math.max(...formattedData.map((item) => item.rewards)))



  if (!userMetricsData) return <Loader />

  return (
    <div className="sw-container h-full">
      <h6 className="text-center text-xl font-semibold p-4"> Functional Strength Analysis</h6>
      {
        tooltipData?.length > 0 &&
        <aside className="fixed z-50 top-1/2 left-14 p-2 w-64 h-[250px] bg-[#232627] overflow-y-auto transform -translate-y-1/2 border-gray-700 border-2 rounded-lg">
          <div className="flex justify-end mb-2">
            <IconButton aria-label="delete" className="!bg-red-600 !text-white" size='small' onClick={() => setTooltipData()}>
              <Close className="!text-sm" />

            </IconButton>

          </div>

          {
            tooltipData?.map((d, index) => (
              <div key={index} className="text-sm">

                <p>
                  <span className="font-bold">Function:</span> {d.name}
                </p>
                <p>
                  <span className="font-bold">Total Runs:</span> {d.totalRuns}
                </p>
                <p>
                  <span className="font-bold">Total Rewards:</span> {d.rewards}
                </p>
                <p>
                  <span className="font-bold">Total Correctly Submitted Solutions:</span> {d.totalCorrectlySubmittedSol}
                </p>
                <p className="mb-2">
                  <span className="font-bold">Accuracy:</span> {d.accuracy.toFixed(2)}%
                </p>
              </div>
            ))
          }

        </aside>

      }
      <div className="flex gap-4 mb-4 px-4">
        <Button
          size="small"
          variant={`${selectedTech === "SQL" ? "contained" : "outlined"}`}
          className={`!shadow-none !text-sm !capitalize !border !border-gray-600 !text-gray-200 ${selectedTech === "SQL" && "!bg-gray-600"}`}
          onClick={(e) => setSelectedTech("SQL")}>
          SQL
        </Button>
        <Button
          size="small"
          variant={`${selectedTech === "Python" ? "contained" : "outlined"}`}
          className={` !shadow-none !text-sm !capitalize !border !border-gray-600 !text-gray-200 ${selectedTech === "Python" && "!bg-gray-600"}`}
          onClick={(e) => setSelectedTech("Python")}>
          Python
        </Button>
        <Button
          size="small"
          variant={`${selectedTech === "Excel" ? "contained" : "outlined"}`}
          className={`!shadow-none !text-sm !capitalize !border !border-gray-600 !text-gray-200 ${selectedTech === "Excel" && "!bg-gray-600"}`}
          onClick={(e) => setSelectedTech("Excel")}>
          Excel
        </Button>

        {disabledIndexes.length > 0 && (
          <Button
            size="small"
            variant={`contained`}
            className={`!shadow-none !text-sm !capitalize !border !border-gray-600 !text-gray-200 !bg-red-500`}
            onClick={clearFilter}> Clear Filter </Button>
        )}
      </div>
      {
        filteredData?.length === 0 ?
          <div className="h-full p-4">
            <h1 className="text-center h-full flex justify-center items-center text-sm text-gray-400">No Data to Display <br></br>Solve Questions to track your performance across different functions & Techstacks!</h1>
          </div> :

          <ResponsiveContainer width="95%" height={250}>
            <ScatterChart
              width={730} height={250} margin={{ top: 5, right: 20, left: 20, bottom: 20 }}
            >
              <defs>
                <linearGradient
                  id="Triangle"
                  x1="0"
                  y1="1"
                  x2="2"
                  y2="0">
                  <stop
                    offset="0%"
                    stopColor="#dc2626"
                    stopOpacity={.3}
                  />
                  <stop
                    offset="100%"
                    stopColor="#4eff00"
                    stopOpacity={.5}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="accuracy"
                name="Accuracy"
                unit="%"
                fontSize={14}
                label={{
                  value: "Accuracy (%)", position: "", fill: "#94a3b8",
                  dy: 14,
                  style: { fontSize: 10 },
                }}
                axisLine={{ stroke: "#94a3b8" }}
                tickLine={{ stroke: "#94a3b8" }}
                tick={{ fill: "#94a3b8" }}
                domain={[0, maxX]}
              />
              <YAxis
                axisLine={{ stroke: "#94a3b8" }}
                tickLine={{ stroke: "#94a3b8" }}
                tick={{ fill: "#94a3b8" }}
                fontSize={14}
                type="number"
                dataKey="rewards"
                name="Rewards"
                label={{
                  value: "Reward Points",
                  angle: -90,
                  fill: "#94a3b8",
                  position: "insideLeft",
                  dy: 40,
                  offset: 10,
                  style: { fontSize: 10 },
                }}
              />
              <Tooltip content={<SWChartCustomTooltip filteredData={filteredData} setTooltipData={setTooltipData} />} />

              <ReferenceArea
                x1={0}
                x2={maxX}
                y1={0}
                fill="url(#Triangle)"
                fillOpacity={0.5}
              />
              <Scatter
                name="SQL Concepts"
                data={filteredData}
                shape={(props) => {
                  const { cx, cy, payload } = props;
                  const pointIndex = payload.index;
                  const isHovered = hoveredIndex === pointIndex; // Hover effect applies only to the hovered point
                  const isHighlighted = highlightedIndex === pointIndex; // Clicked point is highlighted

                  // Group points with the same rewards, accuracy
                  const samePoints =
                    groupData[`${payload.rewards},${payload.accuracy}`];

                  // Size progression for stacked circles (small to large)
                  const size = 10 - samePoints?.indexOf(payload) * 2; // Size varies for stacked points

                  // Get a color from the palette for each circle in a group
                  const colorIndex = samePoints?.indexOf(payload);
                  const color = colorPalette[colorIndex % colorPalette.length];

                  return (
                    <>
                      <circle
                        cx={cx}
                        cy={cy}
                        r={size} // Size varies from small to large
                        fill={isHighlighted ? "#73DF5C" : isHovered ? "#C2C0C3" : color} // Highlight only the hovered or clicked point
                        strokeWidth={1}
                        onClick={() => handlePointClick(pointIndex)}
                        onMouseEnter={() => handleMouseEnter(pointIndex)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          cursor: "pointer",
                          opacity: disabledIndexes.includes(pointIndex) ? 0.3 : 1, // Dims disabled points
                        }}
                      />
                    </>
                  );
                }}
              />
            </ScatterChart>
          </ResponsiveContainer>

      }
    </div>
  )
}

export default SWAccuracyChart