const initialState = {
  freeLimits: {
    sql: {},
    python: {},
    excel: {},
  },
  lastUpdated: null,
}

const GET_FREE_LIMITS = "GET_FREE_LIMITS"
const UPDATE_FREE_LIMITS = "UPDATE_FREE_LIMITS"
const SET_LAST_UPDATED = "SET_LAST_UPDATED"

const freeLimitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FREE_LIMITS:
      return {
        ...state,
        freeLimits: action.payload, // Set full data on first request
      }

    case UPDATE_FREE_LIMITS:
      return {
        ...state,
        freeLimits: {
          ...state.freeLimits,
          ...Object.entries(action.payload).reduce((acc, [tool, levels]) => {
            acc[tool] = {
              ...state.freeLimits[tool], // Preserve existing levels
              ...Object.entries(levels).reduce((levelAcc, [level, newData]) => {
                levelAcc[level] = {
                  totalFreeQuestions: newData.totalFreeQuestions, // Total remains the same
                  remainingFreeQuestions: (state.freeLimits[tool]?.[level]?.remainingFreeQuestions || newData.totalFreeQuestions) - newData.newlySeenQuestions, // Incremental Update
                }
                return levelAcc
              }, {}),
            }
            return acc
          }, {}),
        },
      }

    case SET_LAST_UPDATED:
      return {
        ...state,
        lastUpdated: action.payload,
      }

    default:
      return state
  }
}

export default freeLimitsReducer
