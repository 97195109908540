import React from 'react'
import './reward.css'
import Wallet from './Wallet'
const RewardCoin = ({ isAnimate = true, isWalletVisible = true }) => {
    return (
        <div className='coin relative'>
            {
                isWalletVisible &&
                <Wallet />
            }
            <div className={`front ${isAnimate ? "jump" : ""}`}>
                <div className='star'></div>
                <span className='currency !font-extrabold !-m-1'>M</span>
                <div className='shapes'>
                    <div className='shape_l'></div>
                    <div className='shape_r'></div>
                    <span className='top !text-lg !font-bold !mt-2'>Marma</span>
                    <span className='bottom !text-lg !font-bold !mb-1'>Reward</span>
                </div>
            </div>
            {/* <div className='shadow'></div> */}
        </div>
    )
}

export default RewardCoin