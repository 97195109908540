import React from "react"
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { Provider } from "react-redux"
import { LinkedInCallback } from "react-linkedin-login-oauth2"
import "react-toastify/dist/ReactToastify.css"

import Signup from "./components/signup.js"
import SignIn from "./components/signin.js"

import store from "./redux/store.js"
import QuestionPage from "./components/question2.js"
import PricingPlan from "./components/pricing.js"
import ForgotPassword from "./components/forgot-password.js"
import Profile from "./components/profile.js"
import FAQs from "./components/faqs.js"
import Homepage from "./components/new_home_page/Homepage.jsx"
import ArenaContent from "./components/pre_login_pages/ArenaContent.js"
import ArenaForm from "./components/pre_login_pages/ArenaForm.js"
import AboutUs from "./components/pre_login_pages/AboutUs.js"
import FaqsPage from "./components/pre_login_pages/FaqsPage.js"
import Recruit from "./components/pre_login_pages/Recruit.js"
import RecruitForm from "./components/pre_login_pages/RecruitForm.js"
import Blogs from "./components/pre_login_pages/Blogs.js"
import PersonalDashboard from "./components/dashboard.js"
import Arenas from "./components/arenas.js"
import Arena from "./components/Arena.js"
import Roadmap from "./components/Roadmap.js"
import VerifyEmail from "./components/VerifyEmail.js"
import PublicDashboard from "./components/pre_login_pages/PublicDashboard.jsx"
import SuccessPage from "./components/SuccessPage.js"
import FailedPage from "./components/FailedPage.js"
import ArenaQuestionPage from "./components/arena-question2-page.js"
import MainPage from "./components/main-page.js"
import PublicLayout from "./components/layouts/PublicLayout.jsx"
import PrivateDashboardLayout from "./components/layouts/PrivateDashboardLayout.jsx"
import LeaderBoard from "./components/dashboard/LeaderBoard.jsx"
import Performance from "./components/dashboard/Performance.jsx"
import DashboardSettings from "./components/dashboard/DashboardSettings.jsx"
import PublicDashboarLayout from "./components/layouts/PublicDashboarLayout.jsx"

const App = () => {
  const getRoutes = () => {
    if (localStorage.usertoken)
      return (
        <div className="routescomponents">
          <Routes>
            {/* new dashboard routes */}
            <Route
              path="/dashboard"
              element={<PrivateDashboardLayout />}>
              <Route
                path=""
                element={<LeaderBoard />}
              />

              <Route
                path="/dashboard/performance"
                element={<Performance/>}
              />

              <Route
                path="/dashboard/settings"
                element={<DashboardSettings />}
              />
            </Route>

            {/* end new routes */}

            <Route
              path="/publicdashboard2"
              element={<PublicDashboard />}
            />
            <Route
              path="/publicdashboard"
              element={<PublicDashboarLayout />}
            />
            <Route
              path="/archivedashboard"
              element={<PersonalDashboard />}
            />
            <Route
              path="/faqs"
              element={<FAQs />}
            />
            <Route
              path="/pricing"
              element={<PricingPlan />}
            />
            <Route
              path="/question"
              element={<QuestionPage />}
            />
            <Route
              path="/arena-question/:name/:roadmap"
              element={<ArenaQuestionPage />}
            />
            <Route
              path="/profile/*"
              element={<Profile />}
            />
            <Route
              path="/arenas"
              element={<Arenas />}
            />
            <Route
              path="/arenas/:name"
              element={<Arena />}
            />
            <Route
              path="/arenas/:name/:roadmap"
              element={<Roadmap />}
            />
            <Route
              path="/verify-email"
              element={<VerifyEmail />}
            />
            <Route
              path="/payment-success"
              element={<SuccessPage />}
            />
            <Route
              path="/payment-failed"
              element={<FailedPage />}
            />
            <Route
              path="/home"
              element={<MainPage />}
            />
            <Route
              path="*"
              element={<Navigate to="/question" />}
            />
          </Routes>
        </div>
      )
    else
      return (
        <Routes>
          <Route
            path="/"
            element={<PublicLayout />}>
            <Route
              path="/"
              element={<Homepage />}
            />
            <Route
              path="/arena"
              element={<ArenaContent />}
            />
            <Route
              path="/arenaform"
              element={<ArenaForm />}
            />
            <Route
              path="/about"
              element={<AboutUs />}
            />
            <Route
              path="/preloginfaqs"
              element={<FaqsPage />}
            />
            <Route
              path="/recruit"
              element={<Recruit />}
            />
            <Route
              path="/recruitform"
              element={<RecruitForm />}
            />
            <Route
              path="/signup"
              element={<Signup />}
            />
            <Route
              path="/signin"
              element={<SignIn />}
            />
            <Route
              path="/forgotpwd"
              element={<ForgotPassword />}
            />
            <Route
              path="/blog"
              element={<Blogs />}
            />
            <Route
              path="/linkedin-openid/callback"
              exact
              element={<LinkedInCallback />}
            />
            <Route
              path="/twitter-oauth/callback"
              exact
              element={<SignIn />}
            />
            <Route
              path="*"
              element={<Navigate to="/" />}
            />
            <Route
              path="/publicdashboard"
              element={<PublicDashboard />}
            />
          </Route>
        </Routes>
      )
  }

  return (
    <Provider store={store}>
      <Router>
        <ToastContainer />
        {getRoutes()}
      </Router>
    </Provider>
  )
}

export default App
