import { getFreeLimits } from "../../utils/apis"

// Action Types
export const GET_FREE_LIMITS = "GET_FREE_LIMITS"
export const UPDATE_FREE_LIMITS = "UPDATE_FREE_LIMITS"
export const SET_LAST_UPDATED = "SET_LAST_UPDATED"

// Fetch Free Limits (Initial & Subsequent Requests)
export const fetchLimits = () => {
  return async (dispatch, getState) => {
    try {
      const { lastUpdated } = getState().freeLimits

      // API Call (Pass timestamp if available)
      const queryParams = new URLSearchParams({ lastUpdated: lastUpdated ? lastUpdated : "" })
      const response = await getFreeLimits(queryParams)
      const limits = await response.data

      if (!lastUpdated) {
        dispatch({ type: GET_FREE_LIMITS, payload: limits.freeLimitsData })
      } else if (limits?.freeLimitsData) {
        dispatch({ type: UPDATE_FREE_LIMITS, payload: limits.freeLimitsData })
      }

      if (limits.lastUpdated) {
        dispatch({ type: SET_LAST_UPDATED, payload: limits.lastUpdated })
      }
    } catch (error) {
      console.error("Error fetching free limits:", error)
    }
  }
}
