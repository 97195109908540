import React, { useState, useEffect } from "react"
import "chart.js/auto"
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts"
import { fetchBarChartData } from "../utils/apis.js"
import { toast } from "react-toastify"
import Loader from "./loader.js"
import { getUniqueId } from "../utils/common-function.js"
import { Slider } from "@material-ui/core"
import DatePickerSlider from "../components/ui/data-picker-slider/DatePickerSlider.jsx"
import NewBarChart from "../components/dashboard/NewBarChart.jsx"
import { Button, MenuItem, selectClasses } from "@mui/material"
import { CustomLegend, CustomToolTip, CustomXAxisTick } from "../utils/graphCustomeComponent.js"
import { useSelector } from "react-redux"
import ToastError from "../components/toasts/ToastError.js"
import Dropdown from "../components/ui/Dropdown.jsx"
import { TimerOutlined } from "@mui/icons-material"
import { METRICS } from "../utils/constants.js"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';


const CustomToolTipForBarChart = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload // Access the payload for the hovered bar

    // Filter subjects where value is greater than 0
    const subjectsWithValues = Object.keys(data).filter((subject) => {
      return (subject === "SQL" || subject === "Python" || subject === "Excel") && data[subject] > 0
    })

    // Helper function to render levels for a subject
    const renderLevels = (subject, levels) => {
      return Object.keys(levels).map((level) => <p key={`${subject}-level-${level}`}>{`${subject} - Level ${level}: ${levels[level]}`}</p>)
    }

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#1d1d1d",
          borderRadius: 5,
          padding: 4,
          color: "#e5e7eb",
        }}>
        <p className="label">{`${label}`}</p>
        {subjectsWithValues.map((subject, index) => (
          <div key={index}>
            {/*<p>{`${subject}: ${data[subject]}`}</p> {/* Display the subject value */}
            {renderLevels(subject, data[`${subject}_levels`])} {/* Display levels if they exist */}
          </div>
        ))}
      </div>
    )
  }
  return null
}

const DashboardStackedBarChart = ({ forPublic, filterParams, handleBarChartData }) => {
  const [data, setData] = useState([])
  const [levelWiseChartData, setLevelWiseChartData] = useState([])
  const [monthWiseData, setMonthWiseData] = useState([])
  const [selectedMonth, setSetSelectedMonth] = useState()
  const [selectedMonthData, setSetSelectedMonthData] = useState([])
  const [isProgress, setIsProgress] = useState(true)
  const [highestSolved, setHighestSolved] = useState()

  const [status, setStatus] = useState("idle")
  const [selectedMode, setSetSelectedMode] = useState("timeseries")
  const [initialData, setIntitialData] = useState([])
  const INTERVALS = ["Daily", "Weekly", "Monthly"]

  const [interval, setInterval] = useState("Daily")
  const [metric, setMetric] = useState(METRICS[1].value)


  // const startData = useSelector((state) => state.userProgress.barChartData)

  // useEffect(() => {
  //   setIntitialData(startData)
  // }, [startData])

  //Helper functions

  const preprocessData2 = (rawData, interval, field) => {
    let groupedData = {}

    rawData.forEach((entry) => {
      const { date, tool } = entry
      const entryDate = new Date(date)

      // Determine the interval key and the actual date for sorting
      let intervalKey, sortDate
      if (interval === "Daily") {
        intervalKey = formatDateDaily(entryDate) // Format as 'Jul 29'
        sortDate = entryDate // Use the actual date for sorting
      } else if (interval === "Weekly") {
        const startOfWeek = getStartOfWeek(entryDate)
        const endOfWeek = getEndOfWeek(startOfWeek)
        intervalKey = formatDateWeekly(startOfWeek, endOfWeek) // Format as 'Jul 29 - Aug 6'
        sortDate = startOfWeek // Use the start of the week for sorting
      } else if (interval === "Monthly") {
        intervalKey = formatDateMonthly(entryDate) // Format as 'Sep 2024'
        sortDate = new Date(entryDate.getFullYear(), entryDate.getMonth(), 1) // Use the first day of the month for sorting
      }

      // Initialize the group if it doesn't exist yet
      if (!groupedData[intervalKey]) {
        groupedData[intervalKey] = {
          name: intervalKey, // Use formatted date/week/month as the name
          sortDate: sortDate, // Store the actual date for sorting later
          SQL: 0, // Placeholder for total data aggregation
          Python: 0,
          Excel: 0,
          SQL_levels: {}, // Store level data for tooltip
          Python_levels: {},
          Excel_levels: {},
        }
      }

      // Aggregate data for each tool (SQL, Python, Excel) across all levels
      Object.keys(tool).forEach((subject) => {
        const levels = tool[subject]

        // Sum the data for all levels of the subject (SQL, Python, Excel)
        Object.keys(levels).forEach((level) => {
          groupedData[intervalKey][subject] += levels[level][field] || 0 // Aggregate questionsSolved or rewardPoints

          // Store level data for use in the tooltip
          if (!groupedData[intervalKey][`${subject}_levels`][level]) {
            groupedData[intervalKey][`${subject}_levels`][level] = 0
          }
          groupedData[intervalKey][`${subject}_levels`][level] += levels[level][field] || 0
        })
      })
    })

    // Convert grouped data into an array and sort by the actual date for proper chronological order
    let finalData = Object.values(groupedData)
    finalData.sort((a, b) => a.sortDate - b.sortDate) // Sort by the actual date (sortDate)

    // Limit to the latest 30 records if daily is selected
    if (interval === "Daily" || interval === "Weekly") {
      finalData = finalData.slice(-30) // Get the last 30 entries
    }

    return finalData
  }

  // Helper function to format date for daily intervals as 'Jul 29'
  const formatDateDaily = (date) => {
    const options = { month: "short", day: "numeric" }
    return date.toLocaleDateString("en-US", options) // 'Jul 29'
  }

  // Helper function to format date for weekly intervals as 'Jul 29 - Aug 6'
  const formatDateWeekly = (startOfWeek, endOfWeek) => {
    const startOptions = { month: "short", day: "numeric" }
    const endOptions = { month: "short", day: "numeric" }
    const startStr = startOfWeek.toLocaleDateString("en-US", startOptions)
    const endStr = endOfWeek.toLocaleDateString("en-US", endOptions)
    return `${startStr} - ${endStr}` // 'Jul 29 - Aug 6'
  }

  // Helper function to format date for monthly intervals as 'Sep 2024'
  const formatDateMonthly = (date) => {
    const options = { month: "short", year: "numeric" }
    return date.toLocaleDateString("en-US", options) // 'Sep 2024'
  }

  // Helper function to get the start of the week for a given date (Sunday as the start)
  const getStartOfWeek = (date) => {
    const day = date.getDay()
    const diff = date.getDate() - day // Adjust when the day is Sunday (0)
    return new Date(date.setDate(diff))
  }

  // Helper function to get the end of the week based on the start of the week (Saturday)
  const getEndOfWeek = (startOfWeek) => {
    const endDate = new Date(startOfWeek)
    endDate.setDate(startOfWeek.getDate() + 6) // Move to the end of the week (Saturday)
    return endDate
  }

  const getMonthWiseData = (data) => {
    const monthWiseData = data.reduce((acc, item) => {
      const month = new Date(item.date).toLocaleString('default', { month: 'long', year: 'numeric' });

      // Initialize the month in the accumulator if it doesn't exist
      if (!acc[month]) {
        acc[month] = [];
      }

      // Add the current item to the corresponding month
      acc[month].push(item);
      return acc;
    }, {});

    // Convert the object to an array of objects
    return Object.keys(monthWiseData).map(month => ({
      month,
      details: monthWiseData[month]
    })).reverse();
  };


  const fetchDataForBarChart = async (filterParams) => {
    let uniqueId

    if (forPublic) uniqueId = getUniqueId(window.location.href)
    const response = await fetchBarChartData(uniqueId, filterParams)

    if (response.status === 200) {
      if (response.data.length > 0) {
        setIntitialData(response.data)
        handleBarChartData(response.data)
        setHighestSolved(response.highestSolved)
        const processedData = preprocessData2(response.data, interval, metric)
        setLevelWiseChartData(preprocessData2(response.data, "Monthly", metric))
        setData(processedData)
        setMonthWiseData(getMonthWiseData(response.data))

        processedData.length == 0 ? setStatus("no_data") : setStatus("success")
      } else {
        setStatus("no_data")
      }
    } else {
      setStatus("failure")
      console.error("Failed to fetch data")
      toast(<ToastError message={"Unable to Fetch Your Progress!"} />)
    }
  }

  useEffect(() => {
    if (!(interval === 'Daily')) {
      setSetSelectedMonth()
      setSetSelectedMonthData([])
    }
    const processedData = preprocessData2(initialData, interval, metric)
    setData(processedData)
  }, [metric, interval])

  useEffect(() => {
    fetchDataForBarChart(filterParams)
  }, [filterParams])

  const subjects = ["Python", "SQL", "Excel"] // Adjust based on your data
  const colors = {
    // More vibrant colors for bars
    SQL: ["#9f1239", "#fb7185"],
    Python: ["#86198f", "#e879f9"],
    Excel: ["#166534", "#4ade80"],
  }

  const [sliderValue, setSliderValue] = React.useState([0, data?.length - 1])

  const handleSliderValueChange = (_, newValue) => {
    setSliderValue(newValue)
  }

  useEffect(() => {
    let result;
    if (selectedMonth) {
      result = selectedMonthData?.filter((item) => item.SQL !== 0 || item.Excel !== 0 || item.Python !== 0).length === 0
    } else {
      result = data?.filter((item) => item.SQL !== 0 || item.Excel !== 0 || item.Python !== 0).length === 0
    }
    if (!result) {
      setIsProgress(true)
    } else {
      setIsProgress(false)
    }
    setSliderValue([0, data.length - 1])
  }, [data, selectedMonthData])


  const renderGradients = () => (
    <defs>
      {subjects.map((subject, index) => (
        <linearGradient
          key={subject}
          id={`gradient-${subject}`}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%">
          <stop
            offset="0%"
            stopColor={colors[subject][0] || "#000"}
          />
          <stop
            offset="100%"
            stopColor={colors[subject][1] || "#fff"}
          />
        </linearGradient>
      ))}
    </defs>
  )

  let content

  if (status === "idle") {
    content = <Loader />
  } else if (status === "no_data") {
    content = (
      <h1 className="text-sm text-center h-full flex justify-center items-center text-gray-400">
        No Data to Display <br></br>Solve Questions to see your Progress!
      </h1>
    )
  } else if (status === "failure") {
    content = <h1>Unable to Fetch Data Try Again!</h1>
  } else {
    content = (
      <>
        <div className="flex gap-3 mr-auto w-full items-center flex-wrap sm:justify-center justify-center md:justify-start mb-3 mt-1">
          <Button
            variant="outlined"
            size="small"
            className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${selectedMode === "timeseries" ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
            onClick={() => setSetSelectedMode("timeseries")}>
            Time Series
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${selectedMode === "levelwise" ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
            onClick={() => setSetSelectedMode("levelwise")}>
            Level Wise
          </Button>

          {selectedMode === "timeseries" && data?.length > 0 && (
            <div className="flex gap-3">
              <Dropdown
                title={interval}
                children={INTERVALS.map((interval) => (
                  <MenuItem
                    key={interval}
                    value={interval}
                    onClick={(e) => setInterval(interval)}
                    className="!text-gray-200 !font-light !text-xs">
                    {interval}
                  </MenuItem>
                ))}
              />

              <Dropdown
                title={METRICS.filter((metricItem) => metricItem.value === metric)[0].key}
                icon={metric === 'questionsSolved' ? <CheckCircleIcon className="!text-base" /> : <StarIcon className="!text-base" />}
                children={METRICS.map((metric) => (
                  <MenuItem
                    key={metric?.key}
                    value={metric?.value}
                    onClick={(e) => setMetric(metric.value)}
                    className="!text-gray-200 !font-light !text-xs">
                    {metric.key}
                  </MenuItem>
                ))}
              />

              {
                interval === 'Daily' &&
                <Dropdown
                  title={selectedMonth || "Select Month"}
                  tooltip={`Select month to visualize daliy progress`}
                  children={
                    [
                      <MenuItem
                        onClick={() => {
                          setSetSelectedMonth("Last 30 days");
                          setSetSelectedMonthData(data);
                        }}
                        className="!text-gray-200 !font-light !text-xs"
                        value="Last 30 days"
                        key="last-30-days"
                      >
                        Last 30 days
                      </MenuItem>,
                      ...monthWiseData?.map((item, index) => (
                        <MenuItem
                          onClick={() => {
                            setSetSelectedMonth(item?.month);
                            setSetSelectedMonthData(preprocessData2(item?.details, "Daily", metric));
                          }}
                          className="!text-gray-200 !font-light !text-xs"
                          value={item?.month}
                          key={item?.month}
                        >
                          {item?.month}
                        </MenuItem>
                      ))
                    ]
                  }
                />
              }
            </div>
          )}

          {/* {selectedMode === "timeseries" && interval !== 'Daily' && data?.length > 1 && (
            <div className="relative flex lg:gap-0 m-0 sm:m-0 md:px-4 my-1 items-center flex-col group">
              <span className="absolute top-7 text-gray-400 text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-1/2 transform -translate-x-1/2">Select Duration</span>

              <DatePickerSlider
                sliderData={data}
                onChange={handleSliderValueChange}
                value={sliderValue}
              />
            </div>
          )} */}
        </div>

        {selectedMode === "timeseries" ? (
            <div className={`p-2 h-[calc(100%-100px)] sm:h-[calc(100%-80px)] md:h-[calc(100%-80px)] lg:h-[calc(100%-${isProgress ? "-20" : "100"}px)] xl:h-[calc(100%-${isProgress ? "50" : "55"}px)] w-full flex justify-center items-center`}>
              {/* Enable horizontal scrolling */}
              <div className="w-full">
                <ResponsiveContainer width="100%" height={isProgress ? 250 : 200}>
                  <BarChart
                    data={selectedMonth ? selectedMonthData : data.slice(sliderValue[0], sliderValue[1] + 1)}
                  >
                    {/* Render gradient colors for bars */}
                    {renderGradients()}

                    {/* X-Axis */}
                    <XAxis
                      dataKey="name"
                      axisLine={{ stroke: "#94a3b8" }}
                      tickLine={{ stroke: "#94a3b8" }}
                      tick={<CustomXAxisTick />}
                      interval={0}
                      textAnchor="middle"
                      height={50}
                    />

                    {/* Y-Axis */}
                    <YAxis
                      axisLine={{ stroke: "#94a3b8" }}
                      tickLine={{ stroke: "#94a3b8" }}
                      tick={{ fill: "#94a3b8" }}
                      fontSize={12}
                      allowDecimals={false}
                      domain={[
                        0,
                        interval === "Daily"
                          ? highestSolved?.daily
                          : interval === "Monthly"
                            ? highestSolved?.overall
                            : highestSolved?.weekly,
                      ]}
                      label={{
                        value: metric === "questionsSolved" ? "Questions Solved" : "Reward Points",
                        angle: -90,
                        fill: "#94a3b8",
                        position: "insideLeft",
                        dy: 40,
                        offset: 30,
                        style: { fontSize: 10 },
                      }}
                    />

                    {/* Tooltip */}
                    <Tooltip
                      content={<CustomToolTipForBarChart />}
                      cursor={{ fill: "#374151", opacity: 0.5 }}
                    />

                    {/* Bars for each subject */}
                    {subjects.map((subject) => (
                      <Bar
                        key={subject}
                        dataKey={subject} // Aggregated value for the subject
                        stackId={subject} // Stack bars by subject
                        fill={`url(#gradient-${subject})`}
                        barSize={16}
                        radius={[6, 6, 0, 0]} // Rounded top corners
                        isAnimationActive={false}
                      />
                    ))}

                    {/* Legend */}
                    <Legend
                      iconType="circle"
                      content={<CustomLegend dir="row" />}
                      layout="vertical" // Vertical layout
                      verticalAlign="bottom" // Align vertically to the bottom
                      align="center" // Align horizontally to the center
                    />
                  </BarChart>
                </ResponsiveContainer>

                {/* No progress message */}
                {!isProgress && (
                  <h4 className="text-center text-xs mt-2">
                    You haven't solved any questions during the selected time. <br />
                    To track your progress, either change the filter or start solving questions.
                  </h4>
                )}
              </div>
            </div>
        ) : (
          <NewBarChart data={levelWiseChartData} highestSolved={highestSolved} />
        )}
      </>
    )
  }

  return <div className="h-[350px]">{content}</div>
}

export default DashboardStackedBarChart
