import React, { useEffect, useState } from "react"
import DashboardStackedBarChart from "../../assets/progress-barchart"
import DashboardPieChart from "../../assets/progress-piechart"
import SWAccuracyChart from "../../assets/strength-chart"
import Fchart from "../../assets/frequency-chart"
import { ScreenshotMonitorOutlined, ShareOutlined } from "@mui/icons-material"
import { Button, MenuItem, Tooltip } from "@mui/material"
import Dropdown from "../ui/Dropdown"
import { useOutletContext } from "react-router-dom"
import ShareOnSocial from "react-share-on-social"
import { marmaOnlyLogo } from "../../assets/image_files"
import { fetchUserDetails } from "../../redux/actions/user"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { METRICS, toastConfig } from "../../utils/constants"
import { takeScreenshot } from "../../utils/screenShot"
import HeatMapChart from "./HeatMapChart"
import ToastError from "../toasts/ToastError"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
const Performance = () => {

  const { filterParams, setRewardPoints } = useOutletContext()
  const dispatch = useDispatch()
  const [userUniqueId, setUserUniqueId] = useState("")
  const [barChartData, setBarChartData] = useState([])
  const [userName, setUserName] = useState("")

  const fetchUserUniqueId = async () => {
    try {
      const data = await dispatch(fetchUserDetails())
      setUserName(data?.name)
      setUserUniqueId(data?.uniqueId)
    } catch (error) {
      toast(<ToastError message={"Failed to fetch user details"} />, toastConfig)
    }
  }

  // for pie chart only
  const [metric, setMetric] = useState(METRICS[1].value)
  //pie chart end

  const [isSubmissionChartVisible, setIsSubmissionChartVisible] = useState(false)

  const prepareDataForScreenShot = (refDiv) => {
    const newHeading = document.createElement("h2")
    newHeading.className = "text-sm ms-auto"
    newHeading.innerText = `User Name: ${userName}`

    const userInformationDiv = refDiv.querySelector("#userInformation")

    if (userInformationDiv) {
      userInformationDiv.appendChild(newHeading)

      const originalBackground = refDiv.style.backgroundColor
      refDiv.style.backgroundColor = "black"

      takeScreenshot(refDiv)

      refDiv.style.backgroundColor = originalBackground
      userInformationDiv.removeChild(newHeading)
    } else {
      console.error("Element #userInformation not found within the provided div.")
    }
  }

  useEffect(() => {
    fetchUserUniqueId()
  }, [dispatch])

  return (
    <div className="px-2 h-full">
      <div
        className="text-gray-100 mb-2 flex items-center gap-3"
        id="userInformation">
        <h2 className="text-2xl">Performance</h2>
        <Tooltip
          title={`Share your progress`}
          arrow>
          <button className="bg-gray-200 p-2 rounded-full bg-opacity-10 hover:bg-amber-400 hover:bg-opacity-50 transition-all text-gray-400 hover:text-gray-200">
            <ShareOnSocial
              textToShare="find a detailed overview of my recent progress and achievements. Whether you're a prospective employer, a fellow professional, or just curious, I invite you to delve into the analytics of my journey."
              link={`${window.location.origin}/publicdashboard?id=${userUniqueId}`}
              linkTitle="Welcome to Public Profile"
              linkMetaDesc=""
              linkFavicon={marmaOnlyLogo}
              noReferer>
              <ShareOutlined className="!text-md" />
            </ShareOnSocial>
          </button>
        </Tooltip>

        <Tooltip
          title={`Take screenshot of your progress`}
          arrow>
          <button
            onClick={() => {
              prepareDataForScreenShot(document.body)
            }}
            className="bg-gray-200 p-2 rounded-full bg-opacity-10 hover:bg-amber-400 hover:bg-opacity-50 transition-all text-gray-400 hover:text-gray-200">
            <ScreenshotMonitorOutlined />
          </button>
        </Tooltip>
      </div>
      <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />

      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
        <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[350px]">
          <DashboardStackedBarChart
            forPublic={false}
            filterParams={filterParams}
            handleBarChartData={(data) => setBarChartData(data)}
          />
        </div>

        <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[350px] overflow-hidden">
          <div className="mb-3 flex gap-4 w-max mt-1">
            <Button
              variant="outlined"
              size="small"
              className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${!isSubmissionChartVisible ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
              onClick={() => setIsSubmissionChartVisible(false)}>
              Overall Progress
            </Button>

            <Button
              variant="outlined"
              size="small"
              className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${isSubmissionChartVisible ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
              onClick={() => setIsSubmissionChartVisible(true)}>
              Submissions
            </Button>

            <Dropdown
              title={METRICS.filter((metricItem) => metricItem.value === metric)[0].key}
              icon={metric === 'questionsSolved' ? <CheckCircleIcon className="!text-base" /> : <StarIcon className="!text-base" />}
              children={METRICS.map((metric) => (
                <MenuItem
                  key={metric?.key}
                  value={metric?.value}
                  onClick={(e) => setMetric(metric.value)}
                  className="!text-gray-200 !font-light !text-xs">
                  {metric.key}
                </MenuItem>
              ))}
            />
          </div>
          {isSubmissionChartVisible ? (
            <HeatMapChart data={barChartData} />
          ) : (
            <DashboardPieChart
              metric={metric}
              forPublic={false}
              filterParams={filterParams}
            />
          )}
        </div>

        <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[350px] overflow-hidden">
          <SWAccuracyChart
            forPublic={false}
            filterParams={filterParams}
          />
        </div>

        <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[350px] overflow-hidden">
          <Fchart
            userRewardPoints={(data) => setRewardPoints(data)}
            currUserData={() => { }}
            forPublic={false}
            filterParams={filterParams}
          />
        </div>
      </div>
    </div>
  )
}

export default Performance
