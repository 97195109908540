function createBins(min, max, increment = 200) {
  if (min >= max) {
    throw new Error("Min should be less than Max.");
  }

  const bins = [];
  let start = min;

  while (start <= max) {
    const end = Math.min(start + increment - 1, max);
    bins.push({ Range: `${start}-${end}`, Peoples: 0, Label: "", DisplayRange: "" });
    start = end + 1;
  }

  return bins;
}

export function groupBinsAndCountPeople(data, userName) {
  const rewardPoints = data?.map((user) => {
    return (
      user.totalRewardPoints
    )
  })

  const maxRewardPoints = Math.max(...rewardPoints);

  const bins = createBins(0, maxRewardPoints)

  data.forEach(user => {
    const points = user.totalRewardPoints;
    for (let bin of bins) {
      const [start, end] = bin.Range.split('-').map(Number);
      if (start <= points && points <= end) {
        bin.Peoples += 1;
        if (user.isCurrentUser) {
          bin.Label = userName;
        }
        break;
      }
    }
  });

  // return bins.filter(bin => bin.Peoples > 0);
  return bins


}

export function percentileCalculation(data, userList) {
  const attemptedUsers = data?.filter(user => user?.totalQuestionsAttempted > 0)
    .sort((a, b) => b?.totalRewardPoints - a?.totalRewardPoints);


  const percentile = attemptedUsers?.map((user, index) => {
    const result = ((attemptedUsers?.length - index) / attemptedUsers?.length) * 100
    const name = user?.isCurrentUser ? userList[0]?.name : ""

    return {
      percentile: result,
      name,
      totalRewardPoints: user?.totalRewardPoints,
      isCurrentUser: user?.isCurrentUser
    }
  })

  return groupByPercentile(percentile)
}


function groupByPercentile(data) {
  // Create an array of percentile ranges
  const ranges = [
    { min: 1, max: 10 },
    { min: 11, max: 20 },
    { min: 21, max: 30 },
    { min: 31, max: 40 },
    { min: 41, max: 50 },
    { min: 51, max: 60 },
    { min: 61, max: 70 },
    { min: 71, max: 80 },
    { min: 81, max: 90 },
    { min: 91, max: 100 },
  ];

  // Initialize an array to hold the grouped result
  const groupedData = ranges.map(range => ({
    range: `${range.min}-${range.max}`,
    isCurrentUser: false, // Default value
    name: "",
    totalRewardPoints: 0,
    totalPointsSum: 0, // To store the sum of totalRewardPoints for average calculation
    userCount: 0, // To store the number of users in each range
  }));

  // Group the data into the correct ranges and calculate sum and count of reward points
  data.forEach(item => {
    const { percentile, isCurrentUser, name, totalRewardPoints } = item;
    // Find the appropriate range for this percentile
    for (let i = 0; i < ranges.length; i++) {
      if (percentile >= ranges[i].min && percentile <= ranges[i].max) {
        groupedData[i].isCurrentUser = groupedData[i].isCurrentUser ? true : isCurrentUser;
        groupedData[i].name = groupedData[i].name === "" ? name : groupedData[i].name;
        groupedData[i].totalRewardPoints = Math.max(totalRewardPoints, groupedData[i].totalRewardPoints);

        // Add to the sum of reward points and increment user count
        groupedData[i].totalPointsSum += totalRewardPoints;
        groupedData[i].userCount += 1;
        break;
      }
    }
  });

  // Calculate the average reward points for each group
  groupedData.forEach(group => {
    if (group.userCount > 0) {
      group.averageRewardPoints = Math.round(group.totalPointsSum / group.userCount);
    } else {
      group.averageRewardPoints = 0; // No users in this range
    }
  });

  // Return the grouped data with averageRewardPoints added
  return groupedData;
}
