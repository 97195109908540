import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import userReducer from "./reducers/user"
import pricingReducer from "./reducers/pricing"
import faqsReducer from "./reducers/faqs"
import arenaReducer from "./reducers/arena" // Import the arena reducer
import questionHistoryReducer from "./reducers/questionhistory"
import userMetricsReducer from "./reducers/usermetrics"
import userProgressReducer from "./reducers/user_progress"
import freeLimitsReducer from "./reducers/free-limits"

const rootReducer = combineReducers({
  user: userReducer,
  pricing: pricingReducer,
  faqs: faqsReducer,
  arena: arenaReducer, // Add the arena reducer to the combined reducers
  questionHistory: questionHistoryReducer,
  userMetrics: userMetricsReducer,
  userProgress: userProgressReducer,
  freeLimits: freeLimitsReducer,
})

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store
